<template>
  <div class="neteaseClassifyPage">
    <headGoBack :iCustomizdde="true">
      <template #default>
        <span>
          {{ secondaryClassificationName }}
        </span>
      </template>
    </headGoBack>
    <div class="neteaseClassifyDetailsConBox">
      <div class="menuListBox">
        <div
          class="menuOptBox"
          v-for="(menuitem, menuindex) in menuList"
          :key="menuindex"
          :class="{ activeMenuBox: userChiose == menuitem.class_id }"
          @click="goAuchor(menuitem.class_id)"
        >
          {{ menuitem.name_jp }}
        </div>
      </div>
      <div class="goodsListBox" id="goodsListBox" @scroll="scrollBox">
        <div
          v-for="(onelistItem, onelistIndex) in goodsList"
          :key="onelistIndex"
          :id="onelistItem.classification.class_id"
          class="locationLabel"
        >
          <!-- <span class="locationLabel" :id="onelistItem.classification.class_id">
            {{ onelistItem.classification.name_jp }}
          </span> -->
          <div
            class="listOpt"
            @click="
              $fun.toCommodityDetails(
                secgoodsItem.SPUID,
                secgoodsItem.goods_type
              )
            "
            v-for="(secgoodsItem, secgoodsIndex) in onelistItem.goods"
            :key="secgoodsIndex"
          >
            <div class="imgBox">
              <van-image
                lazy-load
                class="goodsImg"
                :src="secgoodsItem.primaryPicUrl"
              >
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
            </div>

            <p class="goodsTitle" v-if="secgoodsItem.TranslationName">
              {{ secgoodsItem.TranslationName }}
            </p>
            <p class="goodsPrice" v-if="secgoodsItem.price">
              <span class="en">
                {{ $fun.ceil(secgoodsItem.price) }}{{ $fanyi("元") }}
              </span>
              <span class="cn"
                >({{ $fun.ceil(secgoodsItem.jp_price)
                }}{{ $fanyi("円") }})</span
              >
            </p>
            <van-skeleton v-else :row="2" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headGoBack from "../../components/headGoBack/index.vue";
export default {
  data() {
    return {
      menuList: [], //三级菜单列表
      goodsList: [], //商品列表
      secondaryClassificationName: "", //二级菜单名称
      userChiose: "", //用户选择的三级菜单
      alllabelLog: [], //所有的三级标签元素id和对应的高度
    };
  },
  components: {
    headGoBack,
  },
  computed: {},
  created() {
    this.getData();
    this.recommendedGoods(this.$route.query.id);
  },
  methods: {
    scrollBox() {
      let scBox = document.getElementById("goodsListBox");
      // console.log(scBox.scrollTop);
      var divs = document.querySelectorAll("[class='locationLabel']");
      let nowid = "";
      for (let i = divs.length - 1; i >= 0; i--) {
        console.log(scBox.scrollTop, divs[i].offsetTop);
        if (divs[i].offsetTop > scBox.scrollTop) {
          nowid = divs[i].id;
          // break;
        }
        // console.log(nowid);
      }
      if (scBox.scrollTop > divs[divs.length - 2].offsetTop) {
        nowid = divs[divs.length - 1].id;
      }
      this.userChiose = nowid;
      // let alllabelLog = document.getElementsByClassName("locationLabel");
    },
    // 判断是否需要获取数据
    getData() {
      // 获取网易商品分类
      this.$api
        .categoryList({
          class_id: this.$route.query.id,
        })
        .then((res) => {
          // console.log("事件名", res.data);
          if (res.code != 0)
            return this.$message.error(this.$fanyi(res.data.msg));
          //接下来的操作
          this.tyidData(res.data.sub_list);
          this.secondaryClassificationName = res.data.name_jp;
        });
    },
    // 处理数据
    tyidData(olist) {
      let menuList = [];
      olist.forEach((element) => {
        element.sub_list.forEach((sub_list_item) => {
          menuList.push(sub_list_item);
        });
      });
      menuList.sort((a, b) => a.class_id - b.class_id);
      this.menuList = menuList;
    },
    // 获取网易严选商品
    recommendedGoods(id) {
      this.$api
        .categoryGoodsList({
          class_id: id,
        })
        .then((res) => {
          // console.log("事件名", res.data);
          if (res.code != 0)
            return this.$message.error(this.$fanyi(res.data.msg));
          //接下来的操作
          res.data.sort(
            (a, b) => a.classification.class_id - b.classification.class_id
          );
          this.goodsList = res.data;
        });
    },
    goAuchor(id) {
      this.userChiose = id;
      document.querySelector(`[id='${id}']`).scrollIntoView(true);
      let auchor = this.$el.querySelector(`[id='${id}']`);
      let goodsListBox = document.getElementsByClassName("goodsListBox")[0];
      let tto = auchor.offsetTop - 130;
      goodsListBox.scrollTo({
        top: tto, //举例：用户点击 第二个标签后 页面就会滚动到第二个标签的高度
        behavior: "smooth", //丝滑滚动
      });
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.neteaseClassifyPage {
  background-color: #f6f6f6;
  height: 100vh;
  display: flex;
  //   overflow: hidden;
  flex-direction: column;
  .neteaseClassifyDetailsConBox {
    display: flex;
    align-items: flex-start;
    flex: 1;
    height: 20px;

    .menuListBox {
      width: 200px;
      height: 100%;
      background-color: white;
      overflow: auto;
      .menuOptBox {
        height: 96px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        line-height: 26px;
        text-align: center;
      }
      .activeMenuBox {
        color: #bb8a57;
        font-weight: 600;
        background: #f6f6f6;
        border-left: #bb8a57 solid 6px;
      }
    }
    .goodsListBox {
      height: 100%;
      padding-top: 30px;
      overflow: auto;
      .listOpt {
        width: 490px;
        margin: 0 30px 30px;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        border-radius: 6px;
        height: 337px;
        &:last-child {
          margin-right: 0;
        }
        .imgBox {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 200px;
          background: linear-gradient(0deg, #f6f6f6 0%, #ffffff 100%);
          margin-bottom: 15px;
          .goodsImg {
            width: 190px;
            height: 190px;
          }
        }

        .goodsTitle {
          width: 100%;
          font-size: 24px;
          margin-bottom: 15px;
          height: 52px;
          font-weight: 400;
          color: #212121;
          line-height: 26px;
          padding: 0 15px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          white-space: normal;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .goodsPrice {
          font-size: 28px;
          font-weight: 600;
          color: #b4272b;
          line-height: 1;
          padding: 0 15px;
          .en {
            font-size: 28px;
          }
          .cn {
            font-size: 24px;
          }
        }
      }
    }
  }
}
.locationLabel {
  // display: block;
  // height: 0;
  // opacity: 0;
  // border: solid;
}
</style>
